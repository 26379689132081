import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTracking } from "react-tracking";

import { useAppStore } from "@store/AppStore";

export const useGlobalDialog = () => {
  const [{ realLocation }] = useAppStore();
  const { trackEvent } = useTracking();
  const history = useHistory();

  useEffect(() => {
    trackEvent({ action: "Page Loaded" });
  }, []);

  const onClose = useCallback(() => {
    trackEvent({ action: "Close Page" });
    history.replace({
      pathname: realLocation.current.pathname,
      search: realLocation.current.search,
    });
  }, [history]);

  return { onClose };
};
