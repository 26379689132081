import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    minHeight: 100,
    position: "relative",

    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),
});
