import React from "react";
import isNil from "lodash/isNil";
import track from "react-tracking";
import orderBy from "lodash/orderBy";
import { useQuery } from "react-query";

import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";
import { useUserStore } from "@store/UserStore";
import { API, Models } from "@services/api";
import { MessengerItem } from "./MessengerItem";
import { useGlobalDialog } from "@hooks/useGlobalDialog";
import { QUERIES } from "@constants/queries";

const Messenger: React.FC = () => {
  const { onClose } = useGlobalDialog();
  const [{ user }] = useUserStore();
  const { data: chats } = useQuery(QUERIES.USER_CHATS, () =>
    API.contactRequests.getList({ parentUserId: user?.id }),
  );
  const { data: facilities } = useQuery(
    QUERIES.CHAT_FACILITIES,
    () => API.facilityCards.getList({ ids: chats?.map((c) => c.facilityId) }),
    { enabled: !isNil(chats) },
  );
  const css = createCss();

  const getItem = (chat: Models.FacilityContactRequest) => {
    const facility = facilities?.find((f) => f.id === chat.facilityId);

    if (!facility) {
      return null;
    }

    return <MessengerItem facility={facility} contactRequest={chat} />;
  };

  return (
    <Dialog
      open
      onClose={onClose}
      title={`School chats (${chats?.length})`}
      data-test="chats-dialog"
    >
      <div css={css.container}>
        {orderBy(
          chats,
          (c) => {
            return c.responses?.slice(-1)[0].createdAt || c.createdAt;
          },
          "desc",
        )?.map(getItem)}
      </div>
    </Dialog>
  );
};

export default track({ page: "Messenger" })(Messenger);
