import { Interpolation } from "@emotion/react";
import { fade } from "@material-ui/core/styles";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    cursor: "pointer",

    "&:hover": {
      backgroundColor: fade(
        theme.palette.divider,
        theme.palette.action.hoverOpacity,
      ),
    },

    "&:not(:first-of-type)": {
      paddingTop: 8,
    },

    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 8,
    },
  }),

  topSection: {
    display: "flex",
    alignItems: "flex-start",
  },

  name: {
    flex: 1,
    minWidth: 0,
    marginRight: 10,
    height: 24,

    "& span": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: "inline-block",
      width: "100%",
    },
  },
});
