import React from "react";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";

import Typography from "@ui-kit/Typography";
import { MessengerItemProps } from "./types";
import { createCss } from "./styles";
import { must } from "@utils/must";

export const MessengerItem: React.FC<MessengerItemProps> = ({
  facility,
  contactRequest,
}) => {
  const history = useHistory();
  const css = createCss();
  const lastMessage = contactRequest.responses?.slice(-1)[0];
  const messageText = lastMessage?.text || contactRequest.comment;
  const messageDate = lastMessage?.createdAt || must(contactRequest.createdAt);

  const goToInquiry = () => {
    history.push({
      pathname: `/map/${facility.id}/service-inquiry/${contactRequest.id}/response`,
    });
  };

  return (
    <div css={css.root} onClick={goToInquiry}>
      <div css={css.topSection}>
        <Typography bolded color="primary" css={css.name}>
          <span>{facility.name}</span>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {DateTime.fromISO(messageDate).toFormat("MM.dd.yy")}
        </Typography>
      </div>
      <Typography variant="body2" gutterBottom>
        {facility.getFullAddress()}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {messageText.ellipsis(50).replaceAll("\n", " ")}
      </Typography>
    </div>
  );
};
